<template>
  <b-row>
    <b-col cols="3">
      <div class="mb-3">
        <TreeView
          ref="dongTreeView"
          :setDong="setDong"
          :selected="dongSearch == '' ? '전체' : dongSearch"
        ></TreeView>
      </div>

      <b-card>
        <div class="mb-12">
          <b-card-body style="padding-bottom: 0">
            <h5 class="card-title mb-0">세대 추가</h5>
          </b-card-body>
          <div class="d-flex align-items-center">
            <b-form @submit.prevent="addUnit" class="p-3">
              <div class="align-items-end gap-3">
                <div class="d-flex">
                  <b-form-group
                    id="input1"
                    label-cols-sm="12"
                    label-cols-lg="12"
                    content-cols-sm
                    content-cols-lg="12"
                    label="동"
                    label-for="input1"
                    label-class="fw-medium"
                    class="mr-2"
                    style="flex: 1"
                  >
                    <b-form-input
                      id="input1"
                      v-model="dong"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input1"
                    label-cols-sm="12"
                    label-cols-lg="12"
                    content-cols-sm
                    content-cols-lg="12"
                    label="호수"
                    label-for="input1"
                    label-class="fw-medium"
                    style="flex: 1"
                  >
                    <b-form-input
                      id="input1"
                      v-model="ho"
                    ></b-form-input>
                  </b-form-group>
                </div>

                <div class="d-flex">
                  <b-form-group
                    id="input1"
                    label-cols-sm="12"
                    label-cols-lg="12"
                    content-cols-sm
                    content-cols-lg="12"
                    label="이름"
                    label-for="input1"
                    label-class="fw-medium"
                    class="mr-2"
                    style="flex: 1"
                  >
                    <b-form-input
                      id="input1"
                      v-model="name"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="input1"
                    label-cols-sm="12"
                    label-cols-lg="12"
                    content-cols-sm
                    content-cols-lg="12"
                    label="휴대폰번호"
                    label-for="input1"
                    label-class="fw-medium"
                    style="flex: 1"
                  >
                    <b-form-input
                      id="input1"
                      v-model="phone"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <b-form-group>
                  <b-button
                    @click="addUnit"
                    variant="outline-primary"
                    pill
                    class="d-flex align-items-center px-3"
                  >추가</b-button>
                </b-form-group>
              </div>
            </b-form>
          </div>
        </div>

        <div no-body class="mb-12">
          <b-card-body class="p-3 pt-0">
            <h5 class="card-title mb-0">세대 일괄 추가</h5>
            <a href="/unit.xlsx">예시 엑셀 파일 다운로드</a>
          </b-card-body>
          <div class="p-3 d-flex align-items-center">
            <b-form @submit.prevent="addUnit">
              <b-row>
                <b-col cols="12" lg="12">
                  <b-form-group>
                    <b-form-file
                      v-model="file"
                      id="file-large"
                      size="md"
                      accept=".xls,.xlsx"
                    ></b-form-file>
                  </b-form-group>
                  <b-button
                    @click="addUnitExcel"
                    variant="outline-primary"
                    pill
                    class="d-flex align-items-center px-3"
                  >추가</b-button>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>
      </b-card>
    </b-col>

    <b-col cols="9">
      <b-card no-body>
        <b-card-body class="p-3">
          <h5 class="card-title mb-0">세대 목록</h5>
        </b-card-body>

        <div>
          <b-form-group
            label=""
            label-for="per-page-select"
            label-size="sm"
            label-class="fw-medium"
            class="pl-3 d-flex align-items-center mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="search"
                type="text"
                @change="searchChange"
                placeholder="검색"
              ></b-form-input>

              <b-input-group-append>
                <b-button variant="outline-primary" @click="searchChange">
                  search
                </b-button>
                <b-button variant="outline-danger" @click="searchClear"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>

        <b-table
          responsive
          class="mb-0 p-3"
          head-variant="light"
          :items="items"
          :fields="fields"
        >
          <template #cell(idx)="data">
            <b-button
              variant="outline-secondary"
              @click="editUnit(data.item.idx, data.item.dong, data.item.ho, data.item.name, data.item.phone)"
              >수정</b-button
            >&nbsp;
            <b-button
              variant="outline-danger"
              @click="deleteUnit(data.item.idx)"
              >삭제</b-button
            >
          </template>
        </b-table>

        <div class="row p-3 m-0">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            class="my-0"
            @change="changePage"
          ></b-pagination>
        </div>
      </b-card>

      
    </b-col>

    <b-modal id="modal-basic" centered hide-footer>
      <template #modal-title> 세대 수정 </template>
      <div class="d-block">
        <b-col cols="12" lg="12">
          <div class="d-flex">
            <b-form-group
              id="input1"
              label="동"
              label-for="input1"
              label-class="fw-medium"
              class="mr-2"
              style="flex: 1"
            >
              <b-form-input id="input1" v-model="editDong"></b-form-input>
            </b-form-group>

            <b-form-group
              id="input1"
              label-cols-sm="12"
              label-cols-lg="12"
              content-cols-sm
              content-cols-lg="12"
              label="호수"
              label-for="input1"
              label-class="fw-medium"
              style="flex: 1"
            >
              <b-form-input id="input1" v-model="editHo"></b-form-input>
            </b-form-group>
          </div>

          <div class="d-flex">
            <b-form-group
              id="input1"
              label-cols-sm="12"
              label-cols-lg="12"
              content-cols-sm
              content-cols-lg="12"
              label="이름"
              label-for="input1"
              label-class="fw-medium"
              class="mr-2"
              style="flex: 1"
            >
              <b-form-input id="input1" v-model="editName"></b-form-input>
            </b-form-group>

            <b-form-group
              id="input1"
              label-cols-sm="12"
              label-cols-lg="12"
              content-cols-sm
              content-cols-lg="12"
              label="휴대폰번호"
              label-for="input1"
              label-class="fw-medium"
              style="flex: 1"
            >
              <b-form-input id="input1" v-model="editPhone"></b-form-input>
            </b-form-group>
          </div>

          <b-button
            @click="editUnitDo"
            variant="outline-primary"
            pill
            class="d-flex align-items-center px-3"
          >수정</b-button>
        </b-col>
      </div>
    </b-modal>
  </b-row>
</template>

<style>
.modal-body {
  padding-top: 0;
}

.b-table {
  border: 1px solid #eee
}
</style>

<script>
import TreeView from '../components/apps/TreeView';
import { unitList, unitAdd, unitDelete, unitAddExcel, unitEdit, pswdVerifySwal } from '../api.js';
export default {
  name: "PaginationTable",
  data: () => ({
    file: null,
    dong: "",
    ho: "",
    name: "",
    phone: "",
    editIdx: null,
    editDong: "",
    editHo: "",
    editName: "",
    editPhone: "",
    page: {
      title: "PaginationTable",
    },
    fields: [
      {
        key: "dong",
        label: "동",
      },
      {
        key: "ho",
        label: "호",
      },
      {
        key: "name",
        label: "이름",
      },
      {
        key: "phone",
        label: "휴대폰번호",
      },
      {
        key: "idx",
        label: "",
      },
    ],
    items: [

    ],
    dongSearch: "",
    search: "",
    totalRows: 1,
    currentPage: 1,
    perPage: 10,
  }),
  components: {TreeView},
  methods: {
    openPswdVerifySwal(onSuccess){
      pswdVerifySwal(this.$swal, onSuccess);
    },
    setDong(dong, isChild){
      if(isChild){
        this.dongSearch = dong;
        this.currentPage = 1;
        this.search = "";
        this.getUnitList();
      }
      else{
        this.dongSearch = "";
        this.currentPage = 1;
        this.search = "";
        this.getUnitList();
      }
    },
    editUnit(idx, dong, ho, name, phone){
      this.editIdx = idx;
      this.editDong = dong;
      this.editHo = ho;
      this.editName = name;
      this.editPhone = phone;

      pswdVerifySwal(this.$swal, () => {this.$bvModal.show('modal-basic')});
    },
    async addUnitExcel(){
      if(this.file == null){
        this.$bvToast.toast("엑셀 파일을 선택하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      const unitAddExcelRes = await unitAddExcel(this.file);
      if(unitAddExcelRes.error){
        this.$bvToast.toast(unitAddExcelRes.message, {
          title: `오류`,
          variant: "danger",
          solid: true
        });
        if(unitAddExcelRes.loginRequired){
          this.$router.replace("/login");
        }
        
        return;
      }else{
        this.$bvToast.toast(unitAddExcelRes.count + "건의 세대를 추가했습니다.", {
          title: `성공`,
          variant: "success",
          solid: true
        });
        this.currentPage = 1;
        this.search = "";
        await this.getUnitList();
        this.dong = "";
        this.ho = "";
      }
    },
    async deleteUnit(idx){
      pswdVerifySwal(this.$swal, () => {
        this.$swal.fire({
        icon: 'outline-info',
  title: '정말 해당 세대를 삭제하시겠습니까?',
  showDenyButton: false,
  showCancelButton: true,
  confirmButtonText: '삭제',
  confirmButtonColor: '#f64e60',
  cancelButtonText: "취소"
}).then(async (result) => {
  /* Read more about isConfirmed, isDenied below */
  if (result.isConfirmed) {
    let deleteUnitRes = await unitDelete(idx);
    if(deleteUnitRes.error){
      this.$swal.fire('오류', deleteUnitRes.message, 'error')
      if(deleteUnitRes.loginRequired){
          this.$router.replace("/login");
      }

    }else{
      this.$swal.fire('성공', "세대를 삭제했습니다.", 'success')
      this.currentPage = 1;
      this.search = "";
      await this.getUnitList();
      this.dong = "";
      this.ho = "";
    }
  }
})
      });

    },
    async editUnitDo(){
      if(this.editDong == ""){
        this.$bvToast.toast("동을 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.editHo == ""){
        this.$bvToast.toast("호를 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.editName == ""){
        this.$bvToast.toast("이름을 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.editPhone == ""){
        this.$bvToast.toast("휴대폰번호를 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }

      const unitEditRes = await unitEdit(this.editIdx, this.editDong, this.editHo, this.editName, this.editPhone);

      if(unitEditRes.error){
        this.$bvToast.toast(unitEditRes.message, {
          title: `오류`,
          variant: "danger",
          solid: true
        });
        if(unitEditRes.loginRequired){
          this.$router.replace("/login");
        }
        return;
      }else{
        this.$bvModal.hide('modal-basic');
        this.$bvToast.toast("세대를 수정했습니다.", {
          title: `성공`,
          variant: "success",
          solid: true
        });
        this.currentPage = 1;
        this.search = "";
        await this.getUnitList();
      }


    },
    async addUnit(){
      if(this.dong == ""){
        this.$bvToast.toast("동을 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.ho == ""){
        this.$bvToast.toast("호를 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.name == ""){
        this.$bvToast.toast("이름을 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }
      if(this.phone == ""){
        this.$bvToast.toast("휴대폰번호를 입력하세요.", {
          title: `알림`,
          variant: "info",
          solid: true
        })
        return;
      }

      const unitAddRes = await unitAdd(this.dong, this.ho, this.name, this.phone);

      if(unitAddRes.error){
        this.$bvToast.toast(unitAddRes.message, {
          title: `오류`,
          variant: "danger",
          solid: true
        });
        if(unitAddRes.loginRequired){
          this.$router.replace("/login");
        }
        return;
      }else{
        this.$bvToast.toast("세대를 추가했습니다.", {
          title: `성공`,
          variant: "success",
          solid: true
        });
        this.currentPage = 1;
        this.search = "";
        await this.getUnitList();
        this.dong = "";
        this.ho = "";
        this.phone = "";
        this.name = "";
      }


    },
    changePage(page){
      this.currentPage = page;
      this.getUnitList();
    },
    searchChange(){
      this.currentPage = 1;
      this.getUnitList();
    },
    searchClear(){
      this.currentPage = 1;
      this.search = "";
      this.getUnitList();
    },
    async getUnitList(){
      this.$refs.dongTreeView.getDongList();
      let unitListRes = await unitList(this.currentPage, this.search, this.dongSearch);
      if(unitListRes.error == true){
        this.$bvToast.toast(unitListRes.message, {
          title: `오류`,
          variant: "danger",
          solid: true
        })
        if(unitListRes.loginRequired){
          this.$router.replace("/login");
        }
        return;
      }

      this.items = unitListRes.units;
      this.totalRows = unitListRes.count;
    }
  },
  mounted() {
    this.getUnitList();
  },
};
</script>
